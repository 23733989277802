import React, { useState } from 'react';
import BuyModal from '../BuyModal/BuyModal';
import './ShowProduct.css';

const ShowProduct = ({product}) => {

    const {name, img, price} = product;

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);

    // console.log(product);

    return (
        <>
            <div className='col-md-4 mt-5' >
                <div className='m-4 text-center single-item'>
                    <div className="card card-deign" style={{width: "18rem"}} onClick={handleShow}>
                        <img src={img} className="card-img-top" alt="img" style={{height: '186px', width: '286px'}} />
                        <div className="card-body">
                            <p className="card-text">{name}</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                <BuyModal
                show={show}
                name={name}
                price={price}
                setShow={setShow}
                ></BuyModal>
            }
        </>
    );
};

export default ShowProduct;