import './App.css';
import { BrowserRouter , Routes, Route} from 'react-router-dom';
import Home from './Pages/Home/Home';
import Register from './Pages/Login/Register/Register';
import Login from './Pages/Login/Login/Login';
import AuthProvider from './Hooks/AuthProvider';
import MailVerify from './Pages/Login/MailVerify/MailVerify';
import Payment from './Pages/Shared/Product/payment/Payment';

function App() {

  return (
    <div>
      <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/mail-verify" element={<MailVerify />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
