import React from 'react';
import { NavLink, useNavigate, useLocation} from 'react-router-dom';
import { useForm } from "react-hook-form";
import useAuth from '../../../Hooks/useAuth';
import GoogleIcon from '@mui/icons-material/Google';

const Login = () => {

    const { SignInWithPassword , signInWithGoogle} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    // console.log(auth);

    // const handleGoogleSign = (e) =>{
    //     e.preventDefault();
    //     googleSignUp();
    // }

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
            password: ''}
    });
    const onSubmit = ({email, password}) => {
        // console.log({email, password});
        // const {email, password} = data;
        SignInWithPassword(email, password, location, navigate);

    }
    // if(user.email){
    //     navigate('/')
    // }
    const handleHome = (e) =>{

        e.preventDefault();
        navigate('/')

    };

    const signWithGoogle = () =>{
        signInWithGoogle( location, navigate );
        

    }

    return (
        <div className="container">
            <div style={{marginTop: '20px', display: 'flex'}}><button onClick={handleHome}>Back Home</button></div>
        <div className='d-flex justify-content-center mt-4'>
            
            <div>
            
                {/* <div><h5 style={{textAlign: 'center'}}>Unlock</h5></div> */}
                <div className='mt-1 p-4' style={{border: '1px solid #1976d2'}}>
                    <div><h3>Login</h3></div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" {...register("email", { required: true })}/>
                                
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" {...register("password", { required: true })}/>
                                <div id="emailHelp" className="form-text">We'll never share your password with anyone else.</div>
                            </div>
                            <button type="submit" className="btn w-100 " style={{background: '#1976d2', color: 'white'}}>Submit</button>
                            {errors.exampleRequired && <span>This field is required</span>}
                    </form>
                    <div className='text-center'>..................................</div>

                        <div className='text-center mt-4'><button onClick={signWithGoogle} className='border border-none' ><GoogleIcon /> Sign in With Google</button></div>
                        <div className='mt-4 text-center'><small>Create New Account? <span id='register-deign'><NavLink to='/register'>Click Here</NavLink></span></small></div>
                        
                </div>
            </div>

        </div>
        </div>
    );
};

export default Login;