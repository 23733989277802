import React from 'react';
import img from '../../../image/payment_logo.png';

const Footer = () => {
    return (
        <div style={{backgroundColor: 'black', paddingTop: '10px', paddingBottom: '10px'}}>
           <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                    <div>
                        <p style={{color: 'gray', fontSize: '12px', marginBottom: '1px'}}>© 2022 imeizone.com All right reserved</p></div>
                    </div>
                    <div className="col-md-6 text-center" style={{fontSize: '16px'}}>
                    <img src={img} alt=""  />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;