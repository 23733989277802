import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged , signInWithEmailAndPassword , signOut , sendEmailVerification , GoogleAuthProvider , signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import AuthenticationInitialize from '../firebase/Firebase.init';

AuthenticationInitialize()

const useFirebase = () =>{
    const auth = getAuth();
    const [user, setUser] = useState({});
    const [err, setErr] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    

    const createNewUser = ( email , password) =>{

        setIsLoading(true);
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log(user);
            setUser(user);
            verifyEmail();
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setErr(errorCode);
            setErr(errorMessage);
            // ..
          })
          .finally(() => setIsLoading(false))
    };

    const SignInWithPassword = (email, password , location, navigate) =>{

        setIsLoading(true)
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // console.log(user);
            setUser(user);
            const destination = location?.state?.from?.pathname || '/';

            navigate(destination, {replace: true});
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setErr(errorCode);
            setErr(errorMessage);
        })
        .finally(() => setIsLoading(false));
    };

    const SignOutUser = () =>{

        signOut(auth).then(() => {
            // Sign-out successful.
            alert('Sign Out Successful');
            setUser({});
          }).catch((error) => {
            // An error happened.
            
            alert(error);

          })
          .finally(() => setIsLoading(false))
    };

    useEffect(()=>{

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                
            } else {
                // User is signed out
                setUser({});
                // ...
            }
            setIsLoading(false)
        });
        return () => unsubscribe;
    },[auth]);

    const verifyEmail = () =>{

        sendEmailVerification(auth.currentUser)
        .then((result) => {
            // Email verification sent!
            console.log(result);
            // ...
        });
    };

    const signInWithGoogle = (location, navigate) =>{

        const provider = new GoogleAuthProvider(); 

        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result);
            // const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            setUser(user);
            const destination = location?.state?.from?.pathname || '/';

            navigate(destination, {replace: true});
            // console.log(user);
            // ...
        })
    }
    
    return{
        createNewUser,
        SignInWithPassword,
        SignOutUser,
        user,
        err,
        signInWithGoogle,
        isLoading
    }
}

export default useFirebase;