import React from 'react';
import ShowProduct from './ShowProduct/ShowProduct';
import xbox from '../../../image/Image5_nkH2PrQ.jpg';
import skype from '../../../image/Skype.png';
import amazon from '../../../image/amazon.jpg' ;
import usa from '../../../image/itunes usa.jpg';
import spain from '../../../image/spain_.jpg';
import germany from '../../../image/de.jpg';
import canada from '../../../image/canada.jpg';
import uk from '../../../image/uk.jpg';
import italy from '../../../image/itunes italy.jpg';
import fr from '../../../image/itunes fr.jpg';
import netherlands from '../../../image/itunes NL.jpg';
import finland from '../../../image/itunes FI.jpg';
import austria from '../../../image/itunes AU.jpg' ;
import belgium from '../../../image/itunes BE.jpg';


const products = [
    {   
        key: 1,
        name: 'Xbox Live',
        price: '10',
        img: xbox
    },
    {   key: 2,
        name: 'Skype',
        price: '15',
        img: skype
    },
    {   key: 3,
        name: 'Amazone [USA]',
        price: '13',
        img: amazon
    },
    {   key: 4,
        name: 'App Store & itunes[ USA ]',
        price: '15',
        img: usa 
    },
    {   key: 5,
        name: 'App Store & itunes Spain',
        price: '11',
        img: spain
    },
    {   key: 6,
        name: 'App Store & itunes Germany',
        price: '10',
        img: germany,
    },
    {   key: 7,
        name: 'App Store & itunes Canada',
        price: '11',
        img: canada 
    },
    {   key: 8,
        name: 'App Store & itunes UK',
        price: '11',
        img: uk, 
    },
    {   key: 9,
        name: 'App Store & itunes Italy',
        price: '17',
        img: italy 
    },
    {   key: 10,
        name: 'App Store & itunes France',
        price: '11',
        img: fr, 
    },
    {   key: 11,
        name: 'App Store & itunes Netherlands',
        price: '20',
        img: netherlands,  
    },
    {   key: 12,
        name: 'App Store & itunes Finland',
        price: '7',
        img: finland 
    },
    {   key: 13,
        name: 'App Store & itunes Austria',
        price: '13',
        img: austria,
    },
    {   key: 14,
        name: 'App Store & itunes Belgium',
        price: '21',
        img: belgium, 
    },
    
    
]

const Product = () => {
    return (
        <div className='container'>
            <div className="row">
            {
                products?.map((product) => <ShowProduct
                 key={product.key}
                 product={product}
                ></ShowProduct>)
            }
            </div>
        </div>
    );
};

export default Product;