import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import useFirebase from '../../../Hooks/useFirebase';

const Register = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const { createNewUser , user} = useFirebase();

    const { register, handleSubmit,  formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            password: ''}
    });
    // console.log(user.emailVerified );
    const onSubmit = data => {
        console.log(data);
        const {email, password, name} = data;
        createNewUser(email, password, name, location, navigate)
        // signUpWithPassword();

        if(user?.emailVerified === false){
            navigate('/mail-verify');
        }
        
    };

    const handleHome = (e) =>{
        e.preventDefault();
        navigate('/');
    }

    return (
        <div className='container'>
            <div style={{marginTop: '20px'}}><button onClick={handleHome}>Back Home</button></div>
            <div className='d-flex justify-content-center mt-4'>
            <div>
                {/* <div><h5 style={{textAlign: 'center'}}>Unlock</h5></div> */}
                <div className='mt-1 p-4' style={{border: '1px solid #1976d2'}}>
                    <div><h3>register</h3></div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label htmlFor="exampleInputName" className="form-label">Your Name</label>
                            <input type="name" className="form-control" id="exampleInputName" aria-describedby="emailHelp"  {...register("name", {required: true, maxLength: 20, pattern: /^[A-Za-z ]+$/i})}/>
                            {errors.name?.type === 'maxLength' && <p style={{color: 'red', fontSize: '12px'}}>maxLength is 20 character</p>}
                            {errors.name?.type === 'pattern' && <p style={{color: 'red', fontSize: '12px'}}>pattern only character</p>}
                            
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" {...register("email", { required: true , pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/})}/>
                            {errors.email?.type === 'pattern' && <p style={{color: 'red', fontSize: '12px'}}>wrong mail</p>}
                            
                        </div>
                        <div className="mb-1">
                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                            <input type="password" className="form-control" id="exampleInputPassword1" {...register("password", { required: true , minLength: 8})}/>
                            {errors.password?.type === 'minLength' && <p style={{color: 'red', fontSize: '12px'}}>minimum 8 letter</p>}
                            <div id="emailHelp" className="form-text">We'll never share your password with anyone else.</div>
                        </div>
                        
                        <button type="submit" className="btn  w-100 " style={{background: '#1976d2', color: 'white'}}>Submit</button>
                        {errors.exampleRequired && <span>This field is required</span>}
                    </form>
                        <div className='mt-1 text-center'><small>Already Account? <span id='register-deign'><NavLink to='/login'>Click Here</NavLink></span></small></div>
                        
                </div>
                </div>
            </div>
        </div>
    );
};

export default Register;