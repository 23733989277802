import React from 'react';
import {Button , Modal} from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';

const BuyModal = ({ show, setShow , name, price }) => {

    // const navigate = useNavigate();

    const handleClose = () => setShow(false);

    // const handlePayment = (e) =>{
    //     e.preventDefault();

    //     navigate('/payment');
    // }
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {name} <span style={{fontWeight: 700}}>${price}</span>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" >Buy Now</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BuyModal;