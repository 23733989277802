import React ,{useEffect}from 'react';
import useAuth from '../../../Hooks/useAuth';
import {useNavigate} from 'react-router-dom';

const MailVerify = () => {

    const {user} = useAuth();
    const navigate = useNavigate();

    // console.log(user.emailVerified);

    

    useEffect(()=>{
        if(user.emailVerified){
            navigate('/');
        }
    },[user, navigate])
    return (
        <div className='container'>
            <div className=' d-flex justify-content-center mt-5'>
                <div>
                    <ol style={{fontSize: '18px'}}>
                        <li> Verify Your Mail <span><a target="_blank" href="https://mail.google.com/">Click here..</a></span></li>
                        <li> reload page</li>
                    </ol>
                </div>
                    
            </div>
            
        </div>
    );
};

export default MailVerify;