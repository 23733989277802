import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    letterSpacing: 2,
    fontFamily: 'Monospace',
    fontSize: '14px',
    border: 'none',
    borderRadius: 'none',
    boxShadow: 'none'
  }));

const TopNavBar = () => {


    return (
        <Grid container>
            <Grid item xs={6} md={6} sx={{textAlign: 'center', fontFamily: 'Monospace', justifyContent: 'center'}}>
                <Item><CallIcon sx={{fontSize: '16px'}} /> +8801303094999 whatsapp only</Item>
            </Grid>
            <Grid item xs={6} md={6} >
                <Item><MailOutlineIcon sx={{fontSize: '16px'}} /> support@imeizone.com</Item>
            </Grid>
        </Grid>
    );
};

export default TopNavBar;